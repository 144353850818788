var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view" },
    [
      _c("header-section", {
        attrs: {
          heading: "Playlists",
          subheading: "Filter our beats by playlist.",
        },
      }),
      _c("section", { staticClass: "section" }, [
        _c(
          "div",
          {
            staticClass:
              "container tileGenresInstrumentsPlaylists__gridContainer",
          },
          _vm._l(_vm.playlists, function (playlist) {
            return _c(
              "router-link",
              {
                key: playlist.id,
                staticClass: "tilePanel tilePanel--playlistFullWidth",
                attrs: {
                  to: { name: "Playlist", params: { id: playlist.id } },
                },
              },
              [
                _c("div", { staticClass: "tilePanel__imgContainer" }, [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: playlist.image_thumbnail_url,
                        expression: "playlist.image_thumbnail_url",
                      },
                    ],
                    staticClass: "tilePanel__img",
                  }),
                ]),
              ]
            )
          }),
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }