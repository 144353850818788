










import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import HeaderSection from '@/components/HeaderSection.vue'
import TileSlider from '@/components/TileSlider.vue'

import { fetchAllPlaylists } from '@/api/playlists'

@Component({ components: { HeaderSection, TileSlider } })
export default class PlaylistsPage extends Vue {
    playlists: IPlaylist[] = []

    async mounted() {
        this.playlists = await fetchAllPlaylists()
    }
}
